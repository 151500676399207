@charset "utf-8";

// If need, change your variables before importing Bulma
$title-color: #770000;
$link: $title-color;
$background: #fafafa;
$family-serif: "alegreya";
$family-primary: $family-serif;
$container-max-width: 800px;

@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/elements/_all.sass";
@import "../node_modules/bulma/sass/layout/_all.sass";

@import "../node_modules/@fontsource/alegreya/index.css";

